import { checkResponse } from './utils';

export const BASE_URL = 'https://admin.aivas.kz/v3';
// export const BASE_URL = 'http://localhost:8090/v3';
export const FRONT_URL = 'https://aivas.kz';

export const getShopInfo = (shopId) => {
  return fetch(`${BASE_URL}/shop/info?id=${shopId}`, {
    method: 'GET',
  }).then((res) => checkResponse(res));
}

export const sendApplicationRequest = (term, iin, amount, phone, shopId, cityId, otpCode) => {
  return fetch(`${BASE_URL}/shop/send-application-request`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ term, iin, amount, phone, shopId, cityId, otpCode }),
  }).then((res) => checkResponse(res));
};

/* Получение СМС-кода Zaim-Inkar */
export const getCode = (phone, iin) => {
  let formData = new FormData();
  formData.append('iin', iin);
  formData.append('phone', phone);

  return fetch(`${FRONT_URL}/api/payment/zaim-inkar/get-code`, {
    method: 'POST',
    body: formData,
  }).then((res) => checkResponse(res));
}

/* Проверка СМС кода Zaim-Inkar */
export const validateCode = (iin, phone, code) => {
  let formData = new FormData();
  formData.append('phone', phone);
  formData.append('iin', iin);
  formData.append('cod', code);

  return fetch(`${FRONT_URL}/api/payment/zaim-inkar/validate-code`, {
    method: 'POST',
    body: formData,
  }).then((res) => checkResponse(res));
}
