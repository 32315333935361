import './SendApplicationRequest.scss'
import logo from "./images/logo.png"
import InputMask from 'react-input-mask';
import {useEffect, useRef, useState} from "react";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import * as api from '../../../utils/MainApi';
import Preloader from "../../common/components/Preloader/Preloader";
import arrow from "../ApplicationSent/images/arrow.png";

function SendApplicationRequest() {
    const { id } = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const otpCodeRef = useRef(null);
    const [otpCode, setOtpCode] = useState(0);

    const [showCodeVerificationModal, setShowCodeVerificationModal] = useState(false);

    const [isApplicationSent, setIsApplicationSent] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);
    const [shopName, setShopName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [termValue, setTermValue] = useState('zaimInkarInstallment-3');

    const amountRef = useRef(null);
    const [amount, setAmount] = useState(0);
    const [isAmountDisabled, setIsAmountDisabled] = useState(false);
    const [isAmountValid, setIsAmountValid] = useState(0);
    const [amountInputError, setAmountInputError] = useState('');

    const iinRef = useRef(null);
    const [iin, setIin] = useState();
    const [isIinValid, setIsIinValid] = useState(0);
    const [iinInputError, setIinInputError] = useState('');

    const phoneRef = useRef("");
    const [phone, setPhone] = useState();
    const [isPhoneValid, setIsPhoneValid] = useState(0);
    const [phoneInputError, setPhoneInputError] = useState('');

    useEffect(() => {
        setIsLoading(true)
        api.getShopInfo(id)
            .then((res) => {
                setShopName(res.data.name);
            })
            .catch((err) => {
                console.log(err);
                navigate('/');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, navigate])

    useEffect(() => {
        if (isAmountValid && isIinValid && isPhoneValid) {
            setIsFormValid(true);
            return;
        }
        setIsFormValid(false);
    }, [isAmountValid, isIinValid, isPhoneValid, isFormValid]);

    useEffect(() => {
        const sum = searchParams.get('sum');

        if (/[0-9]+/.test(sum)) {
            setIsAmountDisabled(true);
            setIsAmountValid(true);
            setAmount(sum);
        }
    }, [searchParams]);

    function validateAmount(e) {
        let isInputValid = true;
        let errorText = "";

        if (!/[0-9]+/.test(amountRef.current.value)) {
            errorText = 'Поле "Сумма" должно быть целым числом.';
            isInputValid = false;
        }

        if (!amountRef.current.validity.valid) {
            errorText = amountRef.current.validationMessage;
            isInputValid = false;
        }

        setAmount(e.target.value);
        setIsAmountValid(isInputValid);
        setAmountInputError(errorText);
    }

    function validateIin(e) {
        let isInputValid = true;
        let errorText = "";
        let iinRegex = /\d{12}/;

        if (!iinRef.current.validity.valid) {
            errorText = iinRef.current.validationMessage;
            isInputValid = false;
        }

        if (!iinRegex.test(iinRef.current.value)) {
            errorText = 'Заполните это поле.';
            isInputValid = false;
        }

        setIin(e.target.value)
        setIsIinValid(isInputValid);
        setIinInputError(errorText);
    }

    function validatePhone(e) {
        e.preventDefault();
        let regexPhone = /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/;
        let isInputValid = true;
        let errorText = "";

        if (!regexPhone.test(phoneRef.current.value)) {
            errorText = 'Заполните это поле.';
            isInputValid = false;
        }

        setPhone(e.target.value)
        setIsPhoneValid(isInputValid);
        setPhoneInputError(errorText);
    }

    function validateOtpCode(e) {
        e.preventDefault();
        setIsLoading(true)
        const iin = iinRef.current.value;
        const phone = phoneRef.current.value.match(/\+?\d/g).join('');
        const otpCodeValue = otpCodeRef.current.value;

        console.log('otpCodeValue ' + otpCodeValue);
        setOtpCode(otpCodeValue);

        api.validateCode(iin, phone, otpCodeValue)
            .then((res) => {
                console.log(res);
                if (res.status === 'success') {
                    return  Promise.resolve('success!!!');
                }
                return Promise.reject(res.message)
            })
            .then((res) => {
                console.log(res)
                sendApplicationRequest(otpCodeValue);
            })
            .catch((err) => {
                if (err instanceof Promise) {
                    err.then(data => alert(data.message))
                    setIsLoading(false);
                    return;
                }
                console.log(err)
            });
    }

    function getOtpCode (e) {
        e.preventDefault();
        setIsLoading(true)
        const iin = iinRef.current.value;
        const phone = phoneRef.current.value.match(/\+?\d/g).join('');
        api.getCode(phone, iin)
            .then((res) => {
                if (res.status === 'success') {
                    alert('Для подтверждения заказа и оформления рассрочки на Ваш номер выслан СМС-код.');
                    return;
                }
                alert(res.message);
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function sendApplicationRequest(otp) {
        const cityId = searchParams.get('city_id');

        console.log('otp ' + otp)

        api.sendApplicationRequest(
            termValue,
            iin,
            amount,
            phone.match(/\d/g).join(''),
            id,
            cityId,
            otp
        ).then((res) => {
            setIsApplicationSent(true)
        })
        .catch((err) => {
            if (err instanceof Promise) {
                err.then(res => {
                    console.log(res)
                    alert(res.data.message)
                })
                return;
            }
            console.log(err);
        })
        .finally(() => {
            setIsLoading(false);
            setIsFormValid(false);
            setShowCodeVerificationModal(false)
            setIsAmountValid(0);
            setIsIinValid(0);
            setIsPhoneValid(0);
            setIin();
            setPhone();
        });
    }

    function handleTermChange(e) {
        setTermValue(e.target.value);
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        setShowCodeVerificationModal(true);
    }

    function hideSuccessModal() {
        setIsApplicationSent(false);
        setAmount(0);
        setIsAmountDisabled(false);
        setIsFormValid(false);
        setIsAmountValid(0);
        setIsIinValid(0);
        setIsPhoneValid(0);
    }

    function hideCodeVerificationModal(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowCodeVerificationModal(false);
    }

    if (isApplicationSent) {
        return <section className="application-sent">
            <div className="page">
                <img src={arrow} alt="" className="application-sent__image"/>
                <h2 className="application-sent__title">Заявка отправлена.</h2>
                <p className="application-sent__text">
                    Ответ по вашей заявке <br/>
                    поступит в виде СМС. <br/>
                    Спасибо за обращение!
                </p>
                <div className="application-sent__button" onClick={hideSuccessModal}>
                    Вернуться на главную
                </div>
            </div>
        </section>
    }

    if (isLoading) {
        return <Preloader/>
    }

    return (
        <>
            <section className="send-application-request">
                <div className="page">
                    <h2 className="application-sent__title">Рассрочка в магазине {shopName}</h2>

                    <form id="form" noValidate onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label className="form-check form-check-inline" htmlFor="inlineRadio1">
                                <input className="form-check-input" type="radio" name="term" id="inlineRadio1" value="zaimInkarInstallment-3" onChange={handleTermChange} defaultChecked/>
                                <span className="form-check-text">
                                    <img src={logo} alt=""/>
                                    Рассрочка на 3 месяца
                                </span>
                            </label>
                            <label className="form-check form-check-inline" htmlFor="inlineRadio2">
                                <input className="form-check-input" type="radio" name="term" id="inlineRadio2" value="zaimInkarInstallment-6" onChange={handleTermChange}/>
                                <span className="form-check-text">
                                    <img src={logo} alt=""/>
                                    Рассрочка на 6 месяцев
                                </span>
                            </label>
                        </div>

                        <label className={`application-request__field ${isIinValid !== 0 && !isIinValid ? 'has-error' : ''}`}>
                            ИИН
                            <input
                                ref={iinRef}
                                name="iin"
                                id="iin-input"
                                inputMode="numeric"
                                minLength="12"
                                maxLength="12"
                                required
                                onChange={validateIin}
                                value={iin}
                            />
                            <span className="input-error">{iinInputError}</span>
                        </label>

                        <label className={`application-request__field ${isAmountValid !== 0 && !isAmountValid ? 'has-error' : ''}`}>
                            Сумма
                            <input
                                ref={amountRef}
                                name="amount"
                                id="amount-input"
                                type="number"
                                min="0"
                                value={amount}
                                required
                                disabled={isAmountDisabled}
                                onChange={validateAmount}
                                inputMode="numeric"
                            />
                            <span className="input-error">{amountInputError}</span>
                        </label>

                        <label className={`application-request__field ${isPhoneValid !== 0 && !isPhoneValid ? 'has-error' : ''}`}>
                            Телефон
                            <InputMask
                                mask="+7 (999) 999 99 99"
                                name="phone"
                                id="phone-input"
                                value={phone}
                                required
                                onChange={validatePhone}
                                ref={phoneRef}
                                inputMode="numeric"
                            />
                            <span className="input-error">{phoneInputError}</span>
                        </label>
                        <button
                            type="submit"
                            disabled={!isFormValid}
                            className={
                                isFormValid
                                    ? 'profile__button-save'
                                    : 'profile__button-save form__button-save_inactive'
                            }>
                            Далее
                        </button>
                    </form>
                </div>
            </section>
            {showCodeVerificationModal &&
            <div className="modal__wrapper zaim-inkar-iin-modal-form">
                <div className="modal">
                    <div className="icon close" onClick={hideCodeVerificationModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6 6 18" stroke="#25282b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 6 18 18" stroke="#25282b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="modal__title">
                        Согласие на сбор и обработку персональных данных
                    </div>
                        <div className="modal__form">
                            <form id="#zaim-inkar-iin-modal-form" className="form" onSubmit={validateOtpCode}>
                                <div className="form-item">
                                    <input type="number" className="form-input" name="cod-modal"
                                           required
                                           placeholder="Код из СМС"
                                           inputMode="numeric"
                                           minLength="5"
                                           maxLength="5"
                                           ref={otpCodeRef}
                                    />
                                    <div className="error-message">
                                    </div>
                                    <div className="get-code" onClick={getOtpCode}>
                                        Получить код
                                    </div>
                                </div>
                                <div className="agree-with-terms">
                                    Нажимая кнопку "Оформить заказ", вы даете <a href="https://aivas.kz/Согласие на обработку данных.pdf" target="_blank" rel="noreferrer">согласие</a> на обработку и сбор данных.
                                </div>
                                <div className="form__btns">
                                    <button type="submit" className="btn-primary">Оформить заказ</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default SendApplicationRequest;
